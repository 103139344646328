@import '~app/mixins';

$colorBorder: #ddd;

.profile {
  color: $color-grey;
  font-size: 14px;
  position: absolute;
  left: 50px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  padding-bottom: 10px;

  .top {
    align-items: center;
    background-color: #f0f2f6;
    display: flex;
    border-bottom: 2px solid $colorBorder;

    .left {
      margin: 20px 0 10px;
      display: flex;
      align-items: flex-end;

      .logo {
        margin-right: 40px;

        .logoActions {
          text-align: center;

          .link {
            font-size: 12px;

            &:hover {
              text-decoration: underline;
            }

            + .link {
              margin-left: 5px;
            }
          }
        }
      }

      .member {
        margin-bottom: 30px;

        .name {
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 2px;
        }

        .location {
          font-size: 16px;
        }
      }
    }

    .right {
      .cycleLabel {
        margin-bottom: 3px;
      }

      .cycle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 115px;
        width: 100%;
        background-color: #fff;
        padding: 0 25px;
        border: 1px solid $colorBorder;
        color: $color-grey;
        font-size: 12px;

        .remaining {
          display: flex;
          align-items: center;
          margin-right: 30px;

          .days {
            font-weight: bold;
            font-size: 30px;
            margin-right: 15px;
            color: #333;
          }
        }

        .limits {
          flex: 1;

          .limit {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;

            .remainingBar {
              border: 1px solid $colorBorder;
              border-radius: 3px;
              height: 15px;
              width: 300px;
              margin: 2px 0 3px;

              .remainingBarInner {
                background-color: #e64f15;
                height: 100%;
              }
            }

            button {
              width: 165px;
              padding-left: 0;
              padding-right: 0;
            }
          }

          .monthlyProperties {
            font-size: 11px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .tabs {
    background-color: transparent;

    .left {
      padding-top: 15px;
    }

    .right {
      padding-top: 15px;
      padding-left: 30px;
      padding-right: 30px;
      background-color: #eff1f5;
      padding-bottom: 40px;

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .preferences {
          > div {
            margin-top: 20px;
          }
        }

        .buttons {
          padding-top: 10px;
        }
      }
    }

    > ul {
      display: flex;
      list-style-type: none;
      border-bottom: 2px solid $colorBorder;
      padding: 15px 0;
      margin: 0 0 40px;

      li {
        margin-left: 40px;
        font-weight: bold;
        color: $color-grey;
        cursor: pointer;
      }

      :global {
        .react-tabs__tab--selected {
          color: #e64f15;
        }
      }
    }
  }

  .sep {
    margin: 20px 0;
    border-top: 1px solid #aaa;
  }

  .left {
    width: 700px;
    padding: 0 40px;
  }

  .right {
    width: 700px;
  }

  .between {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }

  .remainingCredits {
    font-size: 18px;
    color: #666;
  }

  .header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;

    .norm {
      font-weight: normal;
    }

    .highlight {
      color: #e64f15;
    }
  }

  .error {
    font-weight: bold;
    color: red;
    margin-bottom: 15px;
  }

  .success {
    font-weight: bold;
    color: $color-green;
    margin-bottom: 15px;
  }

  .information {
    border: 1px solid #777;
    padding: 20px;
    line-height: 22px;
    margin-bottom: 25px;
  }

  .fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    label {
      margin-bottom: 8px;
    }

    select {
      height: 40px;
      border-color: #cacaca;
    }

    > div:not(.fields) {
      width: 48%;
      margin-bottom: 25px;
    }

    .sep {
      width: 100% !important;
      height: 1px;
      border-top: 1px solid $color-grey-light;
    }

    .stateZip {
      display: flex;
      justify-content: space-between;

      > :first-child {
        width: 60%;
      }

      > :last-child {
        width: 34%;
      }

      button {
        align-self: flex-end;
        margin-bottom: 6px;
      }
    }

    .cardExp {
      display: flex;
      justify-content: space-between;

      > div {
        width: 30%;
      }
    }
  }

  .field {
    color: $color-grey;
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
      white-space: nowrap;
    }

    select {
      height: 40px;
      border-color: #cacaca;
    }
  }

  select {
    color: $color-grey !important;
  }

  .grid {
    width: 100%;
    background-color: white;
    color: $color-grey;
    font-size: 12px;
    border-collapse: collapse;

    tr {
      &:nth-child(even) {
        background-color: #f5f5f5;
      }

      &:first-child {
        background-color: #e6eaf0;
      }

      td,
      th {
        border: 1px solid #c8c8c8;
        padding: 6px 10px;
        text-align: left;

        .light {
          font-weight: normal;
          color: $color-grey-light;
        }

        &.none {
          font-style: italic;
          text-align: center;
        }
      }
    }
  }

  .strike {
    text-decoration: line-through;
  }

  .radioRow {
    display: flex;
    width: 100% !important;
    margin-bottom: 0 !important;;
  }

  .radio {
    margin-right: 30px;
  }

  .alert {
    border-radius: 5px;
    border: 1px solid $color-red;
    color: $color-red;
    background-color: #fbcbc0;
    margin-top: 5px;
    padding: 6px;
    font-size: 13px;
    display: flex;
    align-items: center;

    .iconWarning {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      fill: $color-red;
    }
  }

  .wrapLabel {
    width: 600px;
    white-space: pre-wrap;
  }
}
